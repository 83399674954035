@use "styles/abstracts/color-styles.scss";
@use '@angular/material' as mat;

html, body {
    height: 100%;
    background: color-styles.$background-without-content-color;
}

body {
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    .mat-header-cell {
        font-weight: 500;
    }
}

$theme: mat.define-light-theme((
    density: -3
));
@include mat.all-component-themes($theme);

.form-input .mat-mdc-form-field-subscript-wrapper {
    height: 16px !important;
}

.invalid-feedback {
    margin-top: 0 !important;
    font-size: 12px;
    font-weight: 400;
    font-family: Roboto, serif;
}
