$white-color: #FFFFFF;
$white-secondary-color: #FFFEFE;
$black-color: #191919;
$red-color: #EF5350;
$background-without-content-color: #F2F2F2;
$primary-blue: #0A4A8E;
$grey-color: #808383;
$hint-text-light-gray: #B0B0B0;
$info-block-color: #E3F2FD;

$default-text: #808383;
$hover-text: #0D6DD3;
$focused-text: #0D6DD3;
$selected-text: #0A4A8E;
$disabled-text: #808383;
$disabled-background: #0000001F;
$disabled-button-text-color: #00000061;
$background-hover: #f8f8fc;
